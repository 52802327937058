import React from "react";
import { CONSULTATION_STATUS } from "../../services/utilityService";
import AttachmentModal from "../modals/AttachmentModal";
import FollowUpConsultationModal from "../modals/FollowUpConsultationModal";
import DoctorOfflineSelectModal from "../modals/DoctorOfflineSelectModal";
import DoctorProfileModal from "../modals/DoctorProfileModal";
import DecisionModal from "../modals/DecisionModal";
import { useTranslation, withTranslation } from "react-i18next";

export const MODALS = {
  endConsultation: "end-consultation",
  selectedDoctorProfile: "selectedDoctor",
  attachment: "attachment",
  referral: "referral",
  pharmacy: "pharmacy",
  doctorOption: "doctor-option",
  followUpConsultation: "follow-up-consultation",
  consultationFulfillment: "consultation-fulfillment",
};
function ActiveModal({
  activeModal,
  consultation,
  displayModal,
  accessToken,
  doctorOnlineStatus,
  checkDoctorOnlineStatus,
  attachments,
  selectedDoctor,
  updateConsultationStatus,
}) {
  const { t } = useTranslation(["consultDoctor"]);

  switch (activeModal) {
    case MODALS.followUpConsultation:
      return (
        <FollowUpConsultationModal
          consultation={consultation}
          onClose={() => displayModal(null, null, checkDoctorOnlineStatus())}
          accessToken={accessToken}
          doctorOnlineStatus={doctorOnlineStatus}
          toggleDoctorOptionModal={() => displayModal(MODALS.doctorOption)}
        />
      );
    case MODALS.doctorOption:
      return (
        <DoctorOfflineSelectModal
          consultation={consultation}
          onClose={displayModal}
          accessToken={accessToken}
        />
      );
    case MODALS.attachment:
      return (
        <AttachmentModal
          attachments={attachments}
          canDelete={false}
          onClose={() => displayModal(null, { attachments })}
        />
      );
    case MODALS.selectedDoctorProfile:
      return (
        <DoctorProfileModal
          doctor={selectedDoctor}
          onClose={() => displayModal(null, { selectedDoctor })}
        />
      );
    case MODALS.endConsultation:
      return (
        <DecisionModal
          title={t("docEndConsultation")}
          content={t("clickYesOrNo")}
          onYes={() => updateConsultationStatus(CONSULTATION_STATUS.closed)}
          onClose={() => updateConsultationStatus(CONSULTATION_STATUS.reopened)}
        />
      );
    default:
      return null;
  }
}

const ActiveModalWithTranslation = withTranslation(["consultDoctor"])(
  ActiveModal
);

export default ActiveModalWithTranslation;
