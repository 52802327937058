import React, { useState } from "react";
import { Button, Heading } from "@reliance/design-system";

import CustomCheckbox from "../inputs/CustomCheckbox";

import { logAnalyticsEvent } from "../../firebaseInit";
import { eventName } from "../../services/constants";

const DeliveryDetail = ({ deliveryKey, value }) => (
  <div style={{ display: "flex", justifyContent: "space-between" }}>
    <div style={{ fontWeight: "bold", paddingRight: 20 }}>{deliveryKey}</div>
    <div style={{ textAlign: "right" }}>{value}</div>
  </div>
);

const AgreementForm = ({ checked, name, agreement, onChange }) => (
  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
    <div>
      {<CustomCheckbox checked={checked} name={name} onChange={onChange} />}
    </div>
    <div>{agreement}</div>
  </div>
);

const FulfilmentDelivery = ({
  handleGoBack,
  onProceed,
  contactDetails,
  prescriptionId,
  ...props
}) => {
  const [checkedItems, setCheckedItems] = useState({
    check1: false,
    check2: false,
    check3: false,
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const allChecked = Object.values(checkedItems).every(Boolean);

  logAnalyticsEvent(eventName.PRESCRIPTION_FULFILMENT_DELIVERY, {
    states: contactDetails?.state,
    item_name: prescriptionId,
  });

  return (
    <div style={{ fontSize: 16 }}>
      <Heading level="h4">Delivery Confirmation</Heading>

      <div style={{ padding: "20px 0px" }}>
        <DeliveryDetail
          deliveryKey="L.G.A"
          value={contactDetails.lga.label || "L.G.A not provided"}
        />
        <DeliveryDetail
          deliveryKey="Address"
          value={
            contactDetails.address ||
            `Address not provided - ${contactDetails.state}`
          }
        />
        <DeliveryDetail
          deliveryKey="Landmark"
          value={contactDetails.landmark}
        />
        <DeliveryDetail
          deliveryKey="Phone Number"
          value={`${
            contactDetails.phoneNumber +
            ", " +
            contactDetails.alternativePhoneNumber
          } `}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          backgroundColor: "#F7F7F8",
          marginBottom: 10,
          padding: 10,
          borderRadius: 8,
        }}
      >
        <AgreementForm
          checked={checkedItems.check1}
          name="check1"
          agreement="I confirm that the information provided above is correct."
          onChange={handleCheckboxChange}
        />
        <AgreementForm
          checked={checkedItems.check2}
          name="check2"
          agreement="I understand that medication requested after 12 PM may not be delivered on the same day."
          onChange={handleCheckboxChange}
        />
        <AgreementForm
          checked={checkedItems.check3}
          name="check3"
          agreement="I understand that attempting to change the delivery address after this request is submitted will cancel the prescription."
          onChange={handleCheckboxChange}
        />
      </div>

      <div
        style={{
          display: "flex",
          gap: 8,
          justifyContent: "space-between",
          padding: "10px 0px 10px 0px",
        }}
      >
        <Button
          onClick={() => {
            logAnalyticsEvent(eventName.REJECT_PRESCRIPTION_DELIVERY_SLA, {
              states: contactDetails?.state,
              item_name: prescriptionId,
            });
            handleGoBack();
          }}
          style={{
            width: "48%",
            fontSize: 15,
            height: 50,
            backgroundColor: "white",
            color: "black",
            borderWidth: "1px",
            borderColor: "#E5E5E7",
          }}
        >
          Go Back
        </Button>
        <Button
          style={{ width: "48%", fontSize: 15, height: 50 }}
          onClick={() => {
            logAnalyticsEvent(
              eventName.VIEW_PRESCRIPTION_DELIVERY_CONFIRMATION,
              {
                states: contactDetails?.state,
                item_name: prescriptionId,
                type: "delivery",
              }
            );

            logAnalyticsEvent(eventName.ACCEPT_PRESCRIPTION_DELIVERY_SLA, {
              states: contactDetails?.state,
              item_name: prescriptionId,
            });
            onProceed();
          }}
          disabled={!allChecked}
        >
          Proceed
        </Button>
      </div>
    </div>
  );
};

export default FulfilmentDelivery;
