import React from "react";
import { Heading } from "@reliance/design-system";
import PharmacyItem from "../PharmacyItem";
import RFCDisclaimer from "../RFCDisclaimer";
import { logAnalyticsEvent } from "../../firebaseInit";
import { eventName } from "../../services/constants";

const FulfilmentRFCPickup = ({
  pharmacy,
  handleSelectedPharmacy,
  contactDetails,
  setPickupType,
  ...props
}) => {
  const { prescriptionId } = props;
  logAnalyticsEvent(eventName.VIEW_PRESCRIPTION_PICKUP_PHARMACIES, {
    states: contactDetails?.state,
    item_name: prescriptionId,
    type: "rfc_pickup",
  });

  logAnalyticsEvent(eventName.PRESCRIPTION_FULFILMENT_RFC_PICKUP, {
    states: contactDetails?.state,
    item_name: prescriptionId,
  });
  return (
    <div>
      <Heading level={"h4"}>Prescription Pick Up</Heading>
      <RFCDisclaimer />
      {pharmacy.map((pharm, index) => (
        <PharmacyItem
          key={index}
          pharmacy={pharm}
          onClick={() => {
            setPickupType("rfc_pickup");
            handleSelectedPharmacy({ ...pharm, isRecommended: true });
          }}
        />
      ))}
    </div>
  );
};

export default FulfilmentRFCPickup;
