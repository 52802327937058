import React, { useState } from "react";
import PharmacyItem from "./PharmacyItem";
import PickupDisclaimer from "./PickupDisclaimer";
import { Heading, Input } from "@reliance/design-system";
import { logAnalyticsEvent } from "../firebaseInit";
import { eventName } from "../services/constants";

export default ({
  pharmacies,
  handleSelectedPharmacy,
  setPickupType,
  contactDetails,
  ...props
}) => {
  const [searchText, setSearchText] = useState("");
  const filteredPharmacies = () => {
    if (pharmacies.length && searchText) {
      return pharmacies.filter(({ name, address }) => {
        if (name && address) {
          return (
            name.toLowerCase().includes(searchText.toLowerCase()) ||
            address.toLowerCase().includes(searchText.toLowerCase())
          );
        }
      });
    }
    return pharmacies;
  };

  const { prescriptionId } = props;

  logAnalyticsEvent(eventName.VIEW_PRESCRIPTION_PICKUP_PHARMACIES, {
    states: contactDetails?.state,
    item_name: prescriptionId,
    type: "pickup",
  });
  logAnalyticsEvent(eventName.PRESCRIPTION_FULFILMENT_OTHER_PICKUP, {
    states: contactDetails?.state,
    item_name: prescriptionId,
  });

  return (
    <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
      <Heading level={"h4"} style={{ padding: "10px 0px 10px 0px" }}>
        Prescription Pick Up
      </Heading>
      <Input
        variant={"search"}
        onChange={(val) => setSearchText(val.target.value)}
        placeholder="Search"
        class="shadow appearance-none border-[#E5E5E7] border rounded-[128px] w-full py-[14px] px-4 text-[15px] leading-[22px] text-black focus:outline-none focus:shadow-outline "
        style={{
          height: 20,
          fontSize: 16,
          padding: "20px 12px 20px 12px",
        }}
      />
      <PickupDisclaimer style={{ marginTop: 60 }} />
      <div>
        {filteredPharmacies().map((val, index) => (
          <PharmacyItem
            key={index}
            pharmacy={val}
            onClick={() => {
              setPickupType("pickup");
              handleSelectedPharmacy({ ...val, isRecommended: false });
            }}
          />
        ))}
      </div>
    </div>
  );
};
