import React from 'react';

export default [
    {
        selector: '[data-tut="step_refer_tour"]',
        content: ({ goTo }) => (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <h4
                        style={{
                            color: '#fff',
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '22px',
                            marginBottom: '12px'
                        }}
                    >
                        Refer
                    </h4>
                </div>
                <p
                    style={{
                        color: '#fff',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        marginBottom: '16px'
                    }}
                >
                    Here you can refer Reliance HMO to friends, family and others by entering their
                    phone numbers and clicking Send invite.
                </p>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <p
                        style={{
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '17px'
                        }}
                    >
                        Step 1/2
                    </p>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <button
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff'
                            }}
                            onClick={() => goTo(1)}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        ),
        style: {
            padding: '24px 32px',
            minWidth: '407px',
            background: '#003F75'
        }
    },
    // {
    //     selector: '[data-tut="step_view_refer_tour"]',
    //     content: ({ goTo }) => (
    //         <div>
    //             <div
    //                 style={{
    //                     display: 'flex',
    //                     justifyContent: 'space-between',
    //                     alignItems: 'center'
    //                 }}
    //             >
    //                 <h4
    //                     style={{
    //                         color: '#fff',
    //                         fontSize: '18px',
    //                         fontWeight: '700',
    //                         lineHeight: '22px',
    //                         marginBottom: '12px'
    //                     }}
    //                 >
    //                     Referrals and Earning
    //                 </h4>
    //             </div>
    //             <p
    //                 style={{
    //                     color: '#fff',
    //                     fontSize: '14px',
    //                     fontWeight: '400',
    //                     lineHeight: '20px',
    //                     marginBottom: '16px'
    //                 }}
    //             >
    //                You can view your referrals and your earnings here.
    //             </p>
    //             <div
    //                 style={{
    //                     display: 'flex',
    //                     justifyContent: 'space-between',
    //                     alignItems: 'center'
    //                 }}
    //             >
    //                 <p
    //                     style={{
    //                         color: '#fff',
    //                         fontSize: '14px',
    //                         fontWeight: '700',
    //                         lineHeight: '17px'
    //                     }}
    //                 >
    //                     Step 2/3
    //                 </p>
    //                 <div
    //                     style={{
    //                         display: 'flex'
    //                     }}
    //                 >
    //                     <button
    //                         style={{
    //                             background: '#00508E',
    //                             padding: '8px 32px',
    //                             borderRadius: '5px',
    //                             fontSize: '14px',
    //                             display: 'block',
    //                             cursor: 'pointer',
    //                             border: '0',
    //                             color: '#fff',
    //                             marginRight: '8px'
    //                         }}
    //                         onClick={() => goTo(0)}
    //                     >
    //                         Prev
    //                     </button>
    //                     <button
    //                         style={{
    //                             background: '#00508E',
    //                             padding: '8px 32px',
    //                             borderRadius: '5px',
    //                             fontSize: '14px',
    //                             display: 'block',
    //                             cursor: 'pointer',
    //                             border: '0',
    //                             color: '#fff'
    //                         }}
    //                         onClick={() => goTo(2)}
    //                     >
    //                         Next
    //                     </button>
    //                 </div>
    //             </div>
    //         </div>
    //     ),
    //     style: {
    //         padding: '24px 32px',
    //         minWidth: '407px',
    //         background: '#003F75'
    //     }
    // },
    {
        selector: '[data-tut="step_socials_tour"]',
        content: ({ goTo }) => (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <h4
                        style={{
                            color: '#fff',
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '22px',
                            marginBottom: '12px'
                        }}
                    >
                        Share Link
                    </h4>
                </div>
                <p
                    style={{
                        color: '#fff',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        marginBottom: '16px'
                    }}
                >
                    You can click to copy and share or easily share to your social media platforms
                </p>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <p
                        style={{
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '17px'
                        }}
                    >
                        Step 2/2
                    </p>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                      <button
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff',
                                marginRight: '8px'
                            }}
                            onClick={() => goTo(1)}
                        >
                            Prev
                        </button>
                        <button
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff'
                            }}
                            onClick={() => (window.location.href = '/profile')}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        ),
        style: {
            padding: '24px 32px',
            minWidth: '407px',
            background: '#003F75'
        }
    }
];
