import React from 'react';
import { Link } from 'react-router-dom';
import MainContent from './boxes/MainContent';
import PageBody from './boxes/PageBody';
import PageHeader from './boxes/PageHeader';

export default function ReferWithdrawCashSuccessPage() {
  return (
    <MainContent>
      <PageHeader title="Withdraw cash" />
      <PageBody>
        <div className="dashboard-card">
          <div className="refer-dashboard-section refer-dashboard-intro">
            <h2 className="dashboard-card__title-text">Success!</h2>
            <p className="dashboard-card__body-text">
              Your money is on its way 
              <span role="img" aria-label="Flying cash bundle">💸</span>
            </p>
            <p className="dashboard-card__body-text">
              If you need help or have any questions, 
              send a mail to <a href="mailto:helloegypt@getreliancehealth.com">helloegypt@getreliancehealth.com</a> 
              or call <a href="tel:070073542623">070073542623</a>
            </p>
            <Link to="/refer/withdraw-cash" className="dashboard-refer__primary-btn">Close</Link>
          </div>
        </div>
      </PageBody>
    </MainContent>
  );
}