import React from "react";
import Info from "../img/Info.svg";

export default () => {
  return (
    <div
      style={{
        backgroundColor: "#FFFAF2",
        padding: 5,
        marginBottom: 10,
        marginTop: 10,
        fontWeight: "bold",
        display: "flex",
        fontSize: "12px",
        flexDirection: "row",
        gap: 5,
      }}
    >
      <div>
        <img src={Info} alt="" width={40} />
      </div>
      <div>
        Prescription is normally ready for pick up within 3 business hours. You
        can pick it up at any time during the opening hours after receiving the
        code.
      </div>
    </div>
  );
};
