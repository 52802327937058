import { CONSULTATION_ACTION_TYPES } from "./actionTypes";

export const displayNewConsultationMessage = (newMessage) => {
  return {
    type: CONSULTATION_ACTION_TYPES.DISPLAY_NEW_MESSAGE,
    newMessage,
  };
};

export const clearNewMessage = (consultation_id) => {
  return {
    type: CONSULTATION_ACTION_TYPES.CLEAR_NEW_MESSAGE,
    consultation_id,
  };
};

export const activateEndConsultation = (data) => {
  return {
    type: CONSULTATION_ACTION_TYPES.ACTIVATE_END_CONSULTATION,
    data,
  };
};

export const clearEndConfirmation = (consultation_id) => {
  return {
    type: CONSULTATION_ACTION_TYPES.CLEAR_END_CONSULTATION,
    consultation_id,
  };
};

export const updateCurrentlyOpenedConsultationStatus = (data) => {
  return {
    type: CONSULTATION_ACTION_TYPES.UPDATE_CONSULTATION_STATUS,
    data,
  };
};

export const updatePrescriptionId = (id) => {
  return {
    type: CONSULTATION_ACTION_TYPES.UPDATE_PRESCRIPTION_ID,
    id,
  };
};

export const updatePrescriptionDetail = data => {
    return {
        type: CONSULTATION_ACTION_TYPES.UPDATE_PRESCRIPTION_DETAIL,
        data
    }
}
