import React from "react";

const styles = {
  baseTextStyle: {
    fontFamily: "Avenir LT Std",
    fontSize: "14px",
    marginBottom: 5,
  },
  containerStyle: {
    padding: 16,
    marginBottom: 8,
    marginTop: 8,
    borderRadius: "4px",
    border: "1px solid var(--ui-06, #EEE)",
    background: "#FFFFFF",
    borderColor: "#F4F4F4",
  },
  headerStyle: {
    fontSize: 16,
    letterSpacing: 0.15,
    color: "var(--primary-color-baby-blue, #107BC0)",
    fontWeight: 700,
  },
  commentHeaderStyle: {
    color: "#C4C4C4",
    fontWeight: 700,
  },
  commentTextStyle: {
    fontWeight: 400,
  },
  quantityStyle: {
    fontSize: 11,
    marginBottom: 5,
    borderRadius: 4,
    padding: "4px 6px",
    background: "#E4E5E7",
    display: "inline-block",
  },
  diagnosisContainerStyle: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
  },
  diagnosisItemStyle: {
    padding: "4px 6px",
    background: "#E4E5E7",
    borderRadius: "4px",
  },
};

const DrugItem = ({ prescriptionDrug }) => {
  const { drug, dosage, quantity, form, comment, diagnosis } = prescriptionDrug;
  return (
    <div style={{ ...styles.containerStyle, ...styles.baseTextStyle }}>
      <div>
        <p style={{ ...styles.headerStyle, ...styles.baseTextStyle }}>
          {drug.name}
        </p>
        <p style={styles.baseTextStyle}>{dosage}</p>
        <div style={styles.quantityStyle}>
          {quantity} {form} dispensed
        </div>
        <div style={styles.diagnosisContainerStyle}>
          {diagnosis.map((item, index) => (
            <div
              key={index}
              style={{ ...styles.diagnosisItemStyle, ...styles.baseTextStyle }}
            >
              {item.name}
            </div>
          ))}
        </div>
        {comment && (
          <div>
            <p
              style={{ ...styles.commentHeaderStyle, ...styles.baseTextStyle }}
            >
              Comments:
            </p>
            <p style={{ ...styles.commentTextStyle, ...styles.baseTextStyle }}>
              {comment}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DrugItem;
