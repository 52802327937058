/**
 * Created by the.last.mayaki on 31/10/2017.
 */
import axios from "axios";
import daaraConfig from "./daara-config.json";
import {
  DAARA_ENROLLEE_BASE_URL,
  DAARA_COOKIE_NAME,
  getCookie,
  BASE_URL_RELIANCE,
} from "./utilityService";
import { sendHttpGetRequest } from "./axiosInterceptor";

const BASE_URL = BASE_URL_RELIANCE;

class ProviderService {
  constructor() {
    this.ax = axios.create({
      baseURL: BASE_URL,
      "Content-Type": "application/json",
    });
    this.data = {};
  }

  getProviders(accessToken, providerTypeId) {
    if (daaraConfig.features.provider_service.get_enrollee_providers) {
      return sendHttpGetRequest(
        `${DAARA_ENROLLEE_BASE_URL}/enrollees/providers${
          providerTypeId ? "?type_id=" + providerTypeId : ""
        }`
      ).then((response) => {
        return response.data;
      });
    }
    if (accessToken) {
      return this.ax
        .get(
          `/enrollees/providers${
            providerTypeId ? "?type_id=" + providerTypeId : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          return response.data.data;
        });
    } else {
      return [];
    }
  }
} // <-- Added closing brace here for the class

export default new ProviderService();
