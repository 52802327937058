import React from 'react';
import * as _ from 'lodash';
import PageHeader from './boxes/PageHeader';
import PageBody from './boxes/PageBody';
import {SyncLoader} from 'react-spinners';
import Tour from 'reactour';
import tourTips from './tours/tourTips'
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";


class TipList extends React.Component {

  constructor() {
    super()
    this.state = {
      searchInput: "",
      canLoadMore: true,
      isTourOpen: false,
    }
  }

  componentDidMount() {
    //perform initial load
    this.props.onLoadMore();
  }

  handleSearch = (event) => {
    this.setState({
      searchInput: event.target.value.substr(0, 10)
    });
  }

  disableBody = (target) => disableBodyScroll(target);
  enableBody = (target) => enableBodyScroll(target);

  closeTour = () => {
      this.setState({ isTourOpen: false });
      localStorage.setItem('isTourOpen', false);
  };

  openTour = () => {
      this.setState({ isTourOpen: true });
  };

  componentDidMount() {
      if (localStorage.getItem('isTourOpen') == null) {
          localStorage.setItem('isTourOpen', true);
          this.setState({ isTourOpen: true });
      } else {
          let isTourOpenSync = localStorage.getItem('isTourOpen');
          this.setState({ isTourOpen: JSON.parse(isTourOpenSync) });
      }
  }

  render() {
    const {isLoadingMore, tipsIdArray, renderTipItem, onLoadMore} = this.props;
    const {isTourOpen} = this.state;
    return (
      <div id="right" className="column content">
        <PageHeader
          title="Health Tips"
        />
        <PageBody>
          <div
            className="tips-wrapper" data-tut="step_tips_tour">
            {tipsIdArray.reverse().map((id) => (
              renderTipItem(_.get(this.props.tipsById, id))
            ))}
          </div>
          <br/>
          <br/>
          {isLoadingMore &&
          <div style={{textAlign: "center", marginTop: "30px"}}>
            <SyncLoader color={'#107BC0'}/>
          </div>
          }
          <br/>
          <div style={{display: `${isLoadingMore ? 'none' : 'flex'}`, justifyContent: "center"}}>
            <button
              onClick={onLoadMore}
              className="infinite-scroll-btn">
              LOAD MORE
            </button>
          </div>
        </PageBody>
        <Tour
          onRequestClose={this.closeTour}
          steps={tourTips}
          isOpen={isTourOpen}
          maskClassName="mask"
          className="helper"
          rounded={12}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
        />
      </div>
    )
  }
}

export default TipList