/**
 * Created by the.last.mayaki on 04/11/2017.
 */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as _ from "lodash";
import * as userActions from "../actions/user";
import MainContent from "../components/boxes/MainContent";
import PageHeader from "../components/boxes/PageHeader";
import PageBody from "../components/boxes/PageBody";
import CenteredContentBlock from "../components/boxes/CenteredContentBlock";
import ChangePasswordForm from "../components/forms/ChangePasswordForm";
import LoadingScreen from "../components/LoadingScreen";
import NotificationBanner from "../components/boxes/NotificationBanner";
import {withTranslation } from 'react-i18next';

class ChangePasswordContainer extends React.Component{

    constructor(props) {
        super(props)
        this.state = {};
    }

    componentDidMount() {
        const context = this;
        this.props.i18n.on('languageChanged', function(lng) {
            if (context.props.error.message && context.state.values && context.state.dispatch) {
                context.onFormSubmit(context.state.values, context.state.dispatch);
            }
        });
    }

    componentDidUpdate() {
        if(this.props.passwordUpdated && !this.state.passwordUpdatedSuccessfully) {
            this.setState({ passwordUpdatedSuccessfully: true });
        }
    }

    onFormSubmit = (values, dispatch) => {
        const { language } = this.props.i18n;
        this.setState({ values, dispatch })
        dispatch(userActions.setNewPassword({ ...values, translate: language === 'ar' }));
    };

    renderLoading = () => {
        return (
            <MainContent>
                <PageHeader title={this.props.t('settings.change_password')}/>
                <PageBody>
                    <LoadingScreen/>
                </PageBody>
            </MainContent>
        )
    };

  renderMessage = (message) => {
    const { passwordUpdated } = this.props;

    return (
      <MainContent>
        <PageHeader title="Change Password" />
        <PageBody>
          <CenteredContentBlock>
            <h1>Notification!</h1>
            <p style={{ fontSize: "12px" }}>{message}</p>
            <br />
            {passwordUpdated ? (
              <a href="/">Go Back</a>
            ) : (
              <button
                className="button_link"
                onClick={() => window.location.reload()}
              >
                Try Again
              </button>
            )}
          </CenteredContentBlock>
        </PageBody>
      </MainContent>
    );
  };

    renderContent = () => {
      const { passwordUpdated, error } = this.props;
        return (
            <MainContent>
                <PageHeader title={this.props.t('settings.change_password')}/>
                <PageBody>
                  {passwordUpdated && <NotificationBanner type="success" message={this.props.t('settings.password_changed')} title={this.props.t('settings.success')}/>}
                  {
                    !_.isEmpty(error) && 
                    <NotificationBanner 
                        type="error" message={error.message || "Sorry, an error seems to have occurred. Please try again later."} 
                        title={this.props.t('error.error')}
                    />
                  }
                  <div className="dashboard-card form">
                    <ChangePasswordForm onSubmit={this.onFormSubmit}/>
                  </div>
                </PageBody>
            </MainContent>
        )
    };

  render() {
    const { isLoading } = this.props;

    if (isLoading) return this.renderLoading();

    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  const { isLoading, error, passwordUpdated } = state.user;
  return { isLoading, error, passwordUpdated };
};

const ChangePasswordContainerWithTranslation = withTranslation(['webapp'])(ChangePasswordContainer);
export default withRouter(connect(mapStateToProps)(ChangePasswordContainerWithTranslation));
