import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as userActions from "../actions/user";
import MainContent from "../components/boxes/MainContent";
import PageHeader from "../components/boxes/PageHeader";
import PageBody from "../components/boxes/PageBody";
import Tab from "../components/boxes/Tab";
import ProductsModal from "../components/modals/ProductsModal";
import CenteredContentBlock from "../components/boxes/CenteredContentBlock";
import LoadingScreen from "../components/LoadingScreen";
import USER_STUB from "../img/user_stub.png";
import * as _ from "lodash";
import Tour from "reactour";
import tourProfile from "../components/tours/tourProfile";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";

class ProfileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      toggleUnassignedProducts: false,
      addDependants: false,
      isTourOpen: false,
    };
  }

  selectTab = (index) => {
    this.setState({
      addDependants: false,
      selectedTab: index,
    });
  };

  disableBody = (target) => disableBodyScroll(target);
  enableBody = (target) => enableBodyScroll(target);

  closeTour = () => {
    this.setState({ isTourOpen: false });
    localStorage.setItem("isTourOpen", false);
  };

  openTour = () => {
    this.setState({ isTourOpen: true });
  };

  componentDidMount() {
    const {
      user,
      dispatch,
      location: { state },
    } = this.props;
    dispatch(userActions.getProfileIfNeeded(user.credentials.accessToken));

    if (state && state.addDependants) {
      this.toggleUnassignedProductsModal();
    }

    if (localStorage.getItem("isTourOpen") == null) {
      localStorage.setItem("isTourOpen", true);
      this.setState({ isTourOpen: true });
    } else {
      let isTourOpenSync = localStorage.getItem("isTourOpen");
      this.setState({ isTourOpen: JSON.parse(isTourOpenSync) });
    }
  }

  toggleUnassignedProductsModal = () => {
    this.setState({
      toggleUnassignedProducts: !this.state.toggleUnassignedProducts,
    });
  };

  selectUnassignedProduct = (product) => {
    const { user, history } = this.props;

    history.push({
      pathname: `/register`,
      state: {
        at: user.credentials.accessToken,
        subscriptionId: product.subscription_id,
        productId: product.id,
      },
    });
  };

  render() {
    const { selectedTab, toggleUnassignedProducts, isTourOpen } = this.state;
    const { principal, isLoading } = this.props;

    if (!isLoading && !_.isEmpty(principal))
      return (
        <MainContent>
          <PageHeader
            title={this.props.t("my_profile.profile")}
            buttonTitle={this.props.t("my_profile.add_family")}
            onButtonClick={this.toggleUnassignedProductsModal}
          />
          {toggleUnassignedProducts && (
            <ProductsModal
              unassignedProducts={principal.unassigned_products}
              selectUnassignedProduct={this.selectUnassignedProduct}
              onClose={this.toggleUnassignedProductsModal}
            />
          )}
          <PageBody>
            <div
              className="dashboard-btn-section profile__tab"
              style={{ flexWrap: "wrap" }}
            >
              <div>
                <Tab
                  title={this.props.t("general.me")}
                  index={0}
                  isActive={selectedTab === 0}
                  onClick={this.selectTab}
                  notClickable={principal.dependants.length < 1}
                />

                {_.orderBy(principal.dependants, "hmo_id").map(
                  (dependant, index) => (
                    <Tab
                      key={index + 1}
                      title={`${dependant.first_name} ${dependant.last_name}`}
                      index={index + 1}
                      isActive={selectedTab === index + 1}
                      onClick={this.selectTab}
                    />
                  )
                )}
              </div>

              <div className="add-family-member-button-container">
                <button onClick={this.toggleUnassignedProductsModal}>
                  {this.props.t("my_profile.add_family")}
                </button>
              </div>
            </div>

            {selectedTab === 0 && <EnrolleeProfile enrollee={principal} />}
            {_.orderBy(principal.dependants, "hmo_id").map(
              (dependant, index) => (
                <div key={index + 1}>
                  {selectedTab === index + 1 && (
                    <EnrolleeProfile enrollee={dependant} />
                  )}
                </div>
              )
            )}
          </PageBody>
          <Tour
            onRequestClose={this.closeTour}
            steps={tourProfile(this.props.t, this.props.i18n)}
            isOpen={isTourOpen}
            maskClassName="mask"
            className="helper"
            rounded={12}
            onAfterOpen={this.disableBody}
            onBeforeClose={this.enableBody}
          />
        </MainContent>
      );
    return (
      <MainContent>
        <LoadingScreen />
      </MainContent>
    );
  }
}

const EnrolleeProfile = (props) => {
  const { enrollee } = props;
  const { t } = useTranslation(["webapp"]);

  return (
    <div
      className="dashboard-card profile-section"
      style={{
        filter: `${enrollee.active_status === 1 ? "none" : "blur(5px)"}`,
      }}
    >
      <img
        src={enrollee.profile_picture ? enrollee.profile_picture : USER_STUB}
        className="profile__img"
        alt={t("my_profile.enrollee")}
        style={{
          filter: `${enrollee.active_status === 1 ? "none" : "blur(5px)"}`,
        }}
      />

      <div className="profile-detail-wrap">
        <div className="profile-item">
          <div className="profile-item-wrapper">
            <p className="profile-item__label">{t("my_profile.full_name")}</p>
            <p className="profile-item__text">{`${enrollee.first_name} ${enrollee.last_name}`}</p>
          </div>
          <div className="profile-item-wrapper">
            <p className="profile-item__label">
              {enrollee.hmo_id
                ? t("my_profile.enrollee_id")
                : t("my_profile.email")}
            </p>
            <p className="profile-item__text">{`${
              enrollee.hmo_id || enrollee.email_address || "N/A"
            }`}</p>
          </div>
        </div>

        <div className="profile-item">
          <div className="profile-item-wrapper">
            <p className="profile-item__label">{t("my_profile.plan")}</p>
            <p className="profile-item__text">{enrollee.plan}</p>
          </div>
          <div className="profile-item-wrapper">
            <p className="profile-item__label">
              {t("my_profile.phone_number")}
            </p>
            <p className="profile-item__text">{`${
              enrollee.phone_number || "N/A"
            }`}</p>
          </div>
        </div>

        <div className="profile-item">
          <div className="profile-item-wrapper">
            <p className="profile-item__label">{t("my_profile.dob")}</p>
            <p className="profile-item__text">{enrollee.date_of_birth}</p>
          </div>
          <div className="profile-item-wrapper">
            <p className="profile-item__label">{t("my_profile.address")}</p>
            <p className="profile-item__text">{enrollee.address}</p>
          </div>
        </div>

        {enrollee.client && enrollee.client !== "N/A" && (
          <div className="profile-item">
            <div className="profile-item-wrapper">
              <p className="profile-item__label">
                {t("my_profile.company_name")}
              </p>
              <p className="profile-item__text">{enrollee.client}</p>
            </div>

            <div className="profile-item-wrapper">
              <p className="profile-item__label">
                {t("my_profile.company_address")}
              </p>
              <p className="profile-item__text">{enrollee.client_address}</p>
            </div>
          </div>
        )}
      </div>
      {enrollee.active_status !== 1 && (
        <CenteredContentBlock>
          <h1 style={{ color: "rgb(9, 65, 99)" }}>
            {t("my_profile.pending_approval")}
          </h1>
        </CenteredContentBlock>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const principal = state.user.meta.profile || {};
  const isLoading = state.user.isLoading;
  return { principal, isLoading };
};

const ProfileContainerWithTranslation = withTranslation(["webapp", "tour"])(
  ProfileContainer
);
export default withRouter(
  connect(mapStateToProps)(ProfileContainerWithTranslation)
);
