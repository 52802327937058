import React from "react";

const ProfileAddress = ({ profile }) => {
  return (
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        alignDelf: "stretch",
        padding: 16,
        marginBottom: 8,
        marginTop: 8,
        textAlign: "center",
        borderRadius: "4px",
        border: "1px solid var(--ui-06, #EEE)",
        background: " #FAFDFF",
      }}
    >
      <div
        style={{
          color: "var(--text-01, #212121)",
          fontSize: "20px",
          fontFamily: "Avenir LT Std",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "20px",
          letterSpacing: "0.15px",
        }}
      >
        {profile.phone_number}, {profile.alternate_phone_number}
      </div>
      <div
        className=""
        style={{
          color: "var(--text-01, #212121)",
          fontWeight: 600,
          display: "flex",
          flexDirection: "column",
          alignSelf: "stretch",
        }}
      >
        {profile?.address}
        {profile?.lga ? `, ${profile.lga}` : ""}
        {profile?.state ? `, ${profile.state}` : ""}
        {profile?.landmark ? ` (${profile.landmark})` : ""}
      </div>
    </div>
  );
};

export default ProfileAddress;
