import locales from '../../config/localesConfig';
import { getSupportedLocales } from '../../services/utilityService';

const primaryLocaleName = locales[getSupportedLocales('primary')];
const isPrimaryLocaleArabic = primaryLocaleName.toUpperCase() === 'ARABIC';

export const languages = {
    [isPrimaryLocaleArabic ? 'ARABIC' : primaryLocaleName.toUpperCase()]: {
        label: isPrimaryLocaleArabic ? 'العربية' : primaryLocaleName.toUpperCase(),
        value: isPrimaryLocaleArabic ? 'ARABIC' : primaryLocaleName.toUpperCase()
    },
    ENGLISH: {
        label: 'ENGLISH',
        value: 'ENGLISH'
    }
};

//add case for added language here (property: value) and return the json file for language (property: language)
export const getLanguage = (language) => {
    switch (language) {
        case languages[primaryLocaleName.toUpperCase()].value:
            return languages[primaryLocaleName.toUpperCase()].language;
        default:
            return languages.ENGLISH.language;
    }
};
