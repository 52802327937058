import React, { useContext } from 'react';
import { FeatureFlagContext } from '../../context/FeatureFlagsContext';

const withFeatureFlags = (WrappedComponent) => {
    return class extends React.Component {
        render() {
            return (
                <FeatureFlagContext.Consumer>
                    {({ flags }) => <WrappedComponent {...this.props} flags={flags} />}
                </FeatureFlagContext.Consumer>
            );
        }
    };
};

export const useFeatureFlags = () => {
    return useContext(FeatureFlagContext);
};

export default withFeatureFlags;
