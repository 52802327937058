import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';
import { getSupportedLocales } from './services/utilityService';

const primaryLocaleCode = getSupportedLocales('primary');
const supportedLanguages = [primaryLocaleCode, 'en-NG'];

const legacyNamespaces = ['signup', 'tour', 'webapp', 'modal', 'doctor-signup', 'consultDoctor'];

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: supportedLanguages,
        backend: {
            // translation file path
            loadPath: (_, ns) => {
                return legacyNamespaces.includes(ns[0])
                    ? '/locale/i18n/{{ns}}/{{lng}}.json'
                    : '/locale/{{lng}}/{{ns}}.json';
            }
        },
        fallbackLng: 'en-NG',
        lng: Cookies.get('i18next') || primaryLocaleCode,
        debug: false,
        // can have multiple namespaces, in case you want to divide a huge
        // translation into smaller pieces and load them on demand
        ns: ['common', 'sign_up', 'signup', 'tour', 'webapp', 'modal'],
        detection: {
            order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie'],
            lookupCookie: 'i18next'
        },
        interpolation: {
            espaceValue: false,
            formatSeparator: ','
        }
    });

export default i18n;
