// src/context/FeatureFlagContext.js
import React, { createContext } from 'react';
import { isFeatureEnabled, getEnvValue } from '../components/utils/featureFlag';

export const FeatureFlagContext = createContext({});

export const FeatureFlagProvider = ({ children }) => {
    const featureFlags = {
        flags: {
            featureViewPrescription: isFeatureEnabled('view_prescription'),
            featureLanguageOptions: isFeatureEnabled('language_options'),
            featureShowLoginUrl: isFeatureEnabled('show_login_url'),
            featureEnableMobileAppDownloads: isFeatureEnabled('enable_mobile_app_downloads'),
            featureEnableSocialLinks: isFeatureEnabled('enable_social_links'),
            iosAppDownloadUrl: getEnvValue('reliance_ios_app_download_url'),
            androidAppDownloadUrl: getEnvValue('reliance_android_app_download_url'),
            supportFacebookUrl: getEnvValue('support_facebook_url'),
            supportWhatsAppNumber: getEnvValue('support_whatsapp_number'),
            supportPhoneNumber: getEnvValue('support_phone_number'),
            supportEmail: getEnvValue('support_email'),
            countryLocale: getEnvValue('country_locale'),
            accountDashboardUrl: getEnvValue('accounts_dashboard_url'),
        }
    };

    return (
        <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>
    );
};
