import { createTypes, async } from 'redux-action-types';
import * as ACTION_CATEGORIES from './actionCategories';

export const TIP_ACTION_TYPES = createTypes(ACTION_CATEGORIES.TIP+'_',
    async('GET_TIPS'),
    async('LIKE_TIP'),
    async('GET_TIP'),
    'INVALIDATE'
);

export const MCHAT_ACTION_TYPES = createTypes(ACTION_CATEGORIES.MCHAT+'_',
    async('GET_MESSAGES'),
    async('SEND_MESSAGE'),
    'INVALIDATE'
);

export const USER_ACTION_TYPES = createTypes(ACTION_CATEGORIES.USER+'_',
    async('LOGIN'),
    async('DAARA_LOGOUT'),
    async('CHANGE_PASSWORD'),
    async('RESET_PASSWORD'),
    async('REGISTER'),
    async('GET_USER'),
    async('GET_PROFILE'),
    async('GET_BENEFITS'),
    'HAS_SETUP_PLANNER',
    'CLEAR_PROFILE'
);

export const PLANNER_ACTION_TYPES = createTypes(ACTION_CATEGORIES.PLANNER+'_',
    async('SET_UP_PLANNER'),
    async('GET_PLANNER'),
    async('UPDATE_PLANNER'),
    async('RESET_PLANNER'),
    'CLEAR_ERROR'
);

export const PROVIDER_ACTION_TYPES = createTypes(ACTION_CATEGORIES.PROVIDER+'_',
    async('GET_PROVIDERS')
);


export const DISPLAY_ACTION_TYPES = createTypes(ACTION_CATEGORIES.DISPLAY+'_',
    'TOGGLE_SIDEBAR',
    'HIDE_SIDEBAR'
);

export const CONSULTATION_ACTION_TYPES = createTypes(ACTION_CATEGORIES.CONSULTATION+'_',
    'DISPLAY_NEW_MESSAGE',
    'CLEAR_NEW_MESSAGE',
    'ACTIVATE_END_CONSULTATION',
    'CLEAR_END_CONSULTATION',
    'UPDATE_CONSULTATION_STATUS',
    'UPDATE_PRESCRIPTION_ID',
    'UPDATE_PRESCRIPTION_DETAIL'
)
