import React from "react";
import moment from "moment";
import DrugItem from "../components/DrugItem";

const styles = {
  containerStyle: {
    marginBottom: 20,
  },
  titleStyle: {
    fontSize: 14,
    fontWeight: 700,
    color: "#333333",
    marginBottom: 5,
  },
  dateStyle: {
    fontSize: 10,
    color: "#999999",
  },
};

const DrugsContainer = ({ prescriptions }) => {
  if (!prescriptions) return null;

  return (
    <div style={styles.containerStyle}>
      <p style={styles.titleStyle}>
        Prescription items - {prescriptions.prescription_drugs.length}
      </p>
      <p style={styles.dateStyle}>
        On {moment(prescriptions.created_at).format("DD/MM/YYYY")}
      </p>
      {prescriptions.prescription_drugs.map((drug, index) => (
        <DrugItem prescriptionDrug={drug} key={index} />
      ))}
    </div>
  );
};

export default DrugsContainer;
