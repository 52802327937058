import React from "react";
import PharmacyItem from "./PharmacyItem";

export default (props) => {
  return (
    <div
      style={{
        textAlign: "center",
        fontSize: "14px",
        fontFamily: "Avenir LT Std",
        fontStyle: "normal",
        lineHeight: "normal",
      }}
    >
      <div
        className=""
        style={{
          display: "flex",
          width: "370px",
          flexDirection: "column",
        }}
      >
        Please confirm that the below address and phone number is accurate and
        valid
      </div>
      <div
        className=""
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          alignDelf: "stretch",
          padding: 16,
          marginBottom: 8,
          marginTop: 8,
        }}
      >
        <div
          style={{
            color: "var(--text-01, #212121)",
            fontSize: "20px",
            fontFamily: "Avenir LT Std",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "20px",
            letterSpacing: "0.15px",
          }}
        >
          {props.profile.phoneNumber}
        </div>
        <div
          className=""
          style={{
            color: "var(--text-01, #212121)",
            fontWeight: 600,
            display: "flex",
            flexDirection: "column",
            alignSelf: "stretch",
          }}
        >
          {props.profile?.address}
        </div>
      </div>
      {props.deliveryMethod == "pickUp" && (
        <div className="" style={{ textAlign: "left" }}>
          <PharmacyItem pharmacy={props.selectedPharmacy} />
        </div>
      )}
      <div
        className=""
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "stretch",
          fontWeight: 400,
          letterSpacing: "-0.1px",
          color: "var(--text-01, #495660)",
          marginBottom: 24,
        }}
      >
        Are you sure you want to proceed?
      </div>
      <div
        className=""
        style={{
          display: "flex",
          gap: "8px",
        }}
      >
        <button
          style={{
            padding: "4px 16px",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            flex: "1 0 0",
            borderRadius: "4px",
            height: "40px",
            background: "var(--primary-color-baby-blue, #107BC0)",
            color: "var(--white, #FFF)",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            letterSpacing: "-0.2px",
          }}
          onClick={props.onNoClicked}
        >
          No
        </button>
        <button
          style={{
            padding: "4px 16px",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            flex: "1 0 0",
            borderRadius: "4px",
            height: "40px",
            background: "var(--primary-color-baby-blue, #107BC0)",
            color: "var(--white, #FFF)",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            letterSpacing: "-0.2px",
          }}
          onClick={props.handleConfirmDelivery}
        >
          Yes, Proceed
        </button>
      </div>
    </div>
  );
};
