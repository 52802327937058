import React, { useState } from "react";
import PharmacyItem from "./PharmacyItem";

export default ({ pharmacies, setModalContent, populateFulfilmentData }) => {
  const [searchText, setSearchText] = useState("");
  const [selectedPharmacy, setSelectedPharmcy] = useState();

  const filteredPharmacies = () => {
    if (pharmacies.length && searchText) {
      return pharmacies.filter(({name,address}) => {
        if(name && address){
          return (
          name.toLowerCase().includes(searchText.toLowerCase()) ||
          address.toLowerCase().includes(searchText.toLowerCase())
        )
        }
      });
    }
    return pharmacies;
  };

  const handleSelectedPharmacy = (pharm, index) => {
    populateFulfilmentData({ selectedPharmacy: pharm });
    setModalContent("confirmPharmacySelection");
    setSelectedPharmcy(index);
  };

  return (
    <div>
      <div
        className=""
        style={{
          display: "flex",
          padding: "12px 16px",
          alignItems: "center",
          gap: "8px",
          alignSelf: "stretch",
          borderRadius: "4px",
          borderWidth: 20,
          background: "var(--ui-06, #EEE)",
          marginBottom: 16,
        }}
      >
        <div
          className=""
          style={{ width: "20px", height: "24px", flexShrink: 0 }}
        >
          <i className="fas fa-search" style={{ fontSize: "20px" }}></i>
        </div>
        <div
          className=""
          style={{
            display: "flex",
            width: "340px",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <input
            type="text"
            style={{
              color: "var(--text-subtle, #767676)",
              fontSize: "18px",
              fontFamily: "Avenir LT Std",
              fontStyle: "normal",
              fontWeight: 350,
              lineHeight: "20px",
              letterSpacing: "0.15px",
              border: 0,
              padding: "10px",
            }}
            placeholder="Search with pharmacy name, area"
            onChange={(val) => setSearchText(val.target.value)}
          />
        </div>
      </div>
      {filteredPharmacies().map((val, index) => (
        <PharmacyItem
          key={index}
          pharmacy={val}
          onClick={() => handleSelectedPharmacy(val, index)}
          isSelected={index === selectedPharmacy}
        />
      ))}
    </div>
  );
};
