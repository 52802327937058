import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';
import { findLocaleByValue, getSupportedLocales } from '../../services/utilityService';
import { languages } from '../utils/constants';
import useLanguageSettings from '../utils/customHooks/useLanguageSettings';

const LanguageSwitcher = () => {
    const { selectedLanguage, setSelectedLanguage, isCurrentLanguageDirectionLTR } =
        useLanguageSettings();
    const { t } = useTranslation('common');

    const handleChangeLanguage = (e) => {
        i18n.changeLanguage(
            e.target.value === 'ENGLISH' ? 'en-NG' : getSupportedLocales('primary')
        );
        setSelectedLanguage(languages[e.target.value.toUpperCase()].label);
        localStorage.setItem('languageOption', languages[e.target.value.toUpperCase()].label);
        if (localStorage.getItem('isTourOpen') === 'true') {
            window.location.reload();
        }
    };

    return (
        <div className={`languageContainer ${isCurrentLanguageDirectionLTR() ? 'right' : 'left'}`}>
            <i className="fas fa-globe-africa lang__icon"></i>
            <select value={selectedLanguage} onChange={handleChangeLanguage}>
                {Object.values(languages).map((i, index) => {
                    const [localeCode, localeName] = findLocaleByValue(i.value);
                    return (
                        <option key={index} value={i.value}>
                            {`${t(localeName.toLowerCase(), {
                                ns: 'common',
                                lng: localeCode
                            })}`.toUpperCase()}
                            &nbsp;&nbsp;
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export default LanguageSwitcher;
