import React from "react";

const LeftArrow = () => {
  return <div>&#8592;</div>;
};

const DialogModal = ({
  isOpen,
  onClose,
  children,
  header,
  backArrow,
  onBackClicked,
  showHeader = true,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-dialog">
        {showHeader && (
          <React.Fragment>
            {backArrow ? (
              <div className="modal-dialog-header">
                <div className="">
                  <i
                    className="fas fa-arrow-left"
                    onClick={onBackClicked}
                    style={{ cursor: "pointer", padding: "10px" }}
                  ></i>{" "}
                  {header}
                  <span className="modal-close-button" onClick={onClose}>
                    X
                  </span>
                </div>
              </div>
            ) : (
              <div className="modal-dialog-header">
                {header}
                <span className="modal-close-button" onClick={onClose}>
                  X
                </span>
              </div>
            )}
          </React.Fragment>
        )}
        <div className="modal-dialog-content">{children}</div>
      </div>
    </div>
  );
};

export default DialogModal;
