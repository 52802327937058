import React from "react";
import moment from "moment";
import SelectPharmacyModal from "../modals/SelectPharmacyModal";
import { useTranslation } from "react-i18next";
import { isFeatureEnabled } from "@reliance/feature-flags";
import { features } from "../../services/constants";

const status = {
  PENDING: "PENDING",
  CANCELLED: "CANCELLED",
  DISPATCHED: "DISPATCHED",
  RECEIVED: "RECEIVED",
  CONFIRMED: "CONFIRMED",
  READY_FOR_PICKUP: "READY_FOR_PICKUP",
  PICKED_UP: "PICKED_UP",
  DELIVERED: "DELIVERED",
  DEACTIVATED: "DEACTIVATED",
};

const isActive = (dispatch_status) => {
  return dispatch_status !== "deactivated" || dispatch_status !== "cancelled";
};

const renderTick = (dispatch_status) => {
  if (dispatch_status) {
    switch (dispatch_status.toUpperCase()) {
      case status.DELIVERED:
      case status.DISPATCHED:
      case status.READY_FOR_PICKUP:
      case status.RECEIVED:
      case status.PICKED_UP:
        return (
          <i
            className="far fa-check-circle"
            style={{
              color: "#09A55A",
              width: "16.29px",
              height: "16.29px",
            }}
          ></i>
        );
      case status.DEACTIVATED:
        return (
          <i
            class="fa fa-ban"
            style={{
              color: "#ed0735",
              width: "16.29px",
              height: "16.29px",
            }}
          ></i>
        );
      default:
        return (
          <i
            className="far fa-clock"
            style={{
              color: "#FCB000",
              width: "16.29px",
              height: "16.29px",
            }}
          ></i>
        );
    }
  } else {
    return null;
  }
};

const dispatchMessage = (dispatchStatus, delivery_option) => {
  const delivery = "Delivery Selected. ";
  const pickUp = "Pharmacy selected. ";
  switch (dispatchStatus.toUpperCase()) {
    case status.PENDING:
      return delivery_option.toLowerCase() === "pickup"
        ? pickUp + "Awaiting confirmation"
        : delivery + "Awaiting confirmation";
    case status.DISPATCHED:
      return delivery + "Out for delivery";
    case status.RECEIVED:
      return delivery + "Prescription received";
    case status.CONFIRMED:
      return delivery_option.toLowerCase() === "pickup"
        ? pickUp + "Prescription confirmed"
        : delivery + "Prescription confirmed";
    case status.READY_FOR_PICKUP:
      return pickUp + "Prescription ready for pickup";
    case status.PICKED_UP:
      return pickUp + "Prescription picked up";
    case status.DELIVERED:
      return delivery + "Prescription delivered";
    case status.DEACTIVATED:
      return "Prescription deactivated";
    default:
      return "Status Unknown";
  }
};

const PrescriptionData = (props) => {
  const { i18n } = useTranslation(["webapp"]);
  const { prescriptions, MESSAGE_TYPE, ATTACHMENT_TYPE } = props;
  if (isFeatureEnabled(features.prescriptionFulfillment)) {
    return (
      <>
        <div
          style={{
            position: "fixed",
            top: "160px",
            zIndex: "",
          }}
        >
          {prescriptions?.dispatch_status && (
            <div className="doctor-chat__bubble prescription-chat ">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                <div
                  data-tooltip="Click here to select a preferred Pharmacy"
                  style={{ margin: "0 1rem 0 0", padding: "1rem" }}
                >
                  <i
                    className="fas fa-prescription-bottle-alt"
                    style={{ cursor: "pointer", fontSize: "20px" }}
                  ></i>
                </div>

                <div style={{ fontSize: "1.3rem" }}>
                  <p>
                    {props.message}.{" "}
                    {isActive(prescriptions?.dispatch_status) && (
                      <em
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={props.showFufillmentModal}
                      >
                        Click here to view
                      </em>
                    )}
                  </p>
                </div>
              </div>

              <p>{moment(props.created_at).format("HH:mm")}</p>
            </div>
          )}
          {prescriptions &&
            prescriptions.delivery_option &&
            props.type === MESSAGE_TYPE.action &&
            props?.attachments[0].type === ATTACHMENT_TYPE.prescription && (
              <div
                className=""
                style={{ textAlign: "left", paddingLeft: "25px" }}
              >
                {renderTick(prescriptions?.dispatch_status)}
                {dispatchMessage(
                  prescriptions?.dispatch_status,
                  prescriptions?.delivery_option
                )}
              </div>
            )}
        </div>
      </>
    );
  } else {
    return (
      <React.Fragment>
        {props.showPharmacyModal && (
          <SelectPharmacyModal
            onClose={props.togglePharmacyModal}
            userToken={props.userToken}
            pharmacies={props.pharmacies}
            consultationId={props.consultationId}
            prescriptionId={props.attachments[0].content.id}
          />
        )}

        <div style={{ display: "flex" }}>
          <div
            data-tooltip="Click here to select a preferred Pharmacy"
            style={{ margin: "0 1rem 1rem 0", padding: "1rem" }}
          >
            <i
              className="fas fa-prescription-bottle-alt"
              style={{ cursor: "pointer", fontSize: "20px" }}
              onClick={() => props.togglePharmacyModal(props.pharmacyModalName)}
            ></i>
          </div>

          <div style={{ fontSize: "1.3rem" }}>
            <p> {props.message}</p>
            <p>
              {moment(props.created_at).locale(i18n.language).format("HH:mm")}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default PrescriptionData;
