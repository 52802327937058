import React from "react";
import bag from "../../src/img/error.png";

const styles = {
  button: {
    display: "flex",
    width: "292px",
    padding: "4px 16px",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    background: "var(--primary-color-baby-blue, #107BC0)",
    color: "var(--white, #FFF)",
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
  content: {
    color: "var(--graphite, #495660)",
    fontSize: "13.102px",
    fontStyle: "normal",
    fontWeight: 400,
    textAlign: "center",
  },
  header: {
    color: "var(--black, #10111E)",
    fontSize: "19.653px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  container: {
    display: "flex",
    padding: "12px 12px",
    flexDirection: "column",
    alignItems: "center",
    gap: "18px",
    fontFamily: "Avenir",
  },
};

export default ({ onClick }) => {
  return (
    <div style={styles.container}>
      <div>
        <img src={bag} alt="" />
      </div>
      <div style={styles.header}>Prescription Deleted</div>

      <div style={styles.content}>
        The doctor has deleted this prescription, you can not proceed. Please
        chat them to learn why
      </div>

      <button style={styles.button} onClick={onClick}>
        Back to Consultation
      </button>
    </div>
  );
};
