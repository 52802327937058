import React from "react";
import "./CustomCheckbox.css";
const CustomCheckbox = ({ checked, name, onChange }) => {
  return (
    <label className="custom-checkbox">
      <input
        type="checkbox"
        checked={checked}
        name={name}
        onChange={onChange}
      />
      <span class="checkmark">
        <i class="fas fa-check"></i>
      </span>
    </label>
  );
};

export default CustomCheckbox;
