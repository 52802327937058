import React, { useState, useEffect } from "react";
import { Modal } from "@reliance/design-system/dist/index.mjs";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FulfillmentBase from "../../FulfillmentBase";
import FulfilmentOptions from "../../FulfilmentOptions";
import Back from "../.../../../../img/Back.svg";
import { Heading } from "@reliance/design-system";
import FulfilmentDelivery from "../../forms/FulfilmentDelivery";
import FulfilmentRFCPickup from "../../forms/FulfilmentRFCPickup";
import ContactDetails from "../../fulfillmentScreens/ContactDetails";
import FulfilmentPharmacyListV2 from "../../FulfilmentPharmacyListV2";
import ConfirmPharmacySelectionV2 from "../../ConfirmPharmacySelectionV2";
import FulfilmentConfirmation from "../../FulfilmentConfirmation";
import LoadingScreen from "../../LoadingScreen";
import { ENDPOINTS } from "../../../services/ApiService.js";

import FulfillmentError from "../../FulfillmentError.js";
import DeliveryStatus from "../../DeliveryStatus.js";
import DialogModal from "../../boxes/DialogModal.js";

const MODALNAVIGATOR = {
  fulfilmentBase: "fulfilmentBase",
  viewPrescription: "viewPrescription",
  fullfillmentOptions: "fullfillmentOptions",
  contactDetails: "contactDetails",
  pickupOption: "pickupOption",
  pickupOptionRecommended: "pickupOptionRecommended",
  deliverOption: "deliverOption",
  deliveryConfirmation: "deliveryConfirmation",
  confirmSelectedPharmacy: "confirmRecommendedSelectedPharmacy",
  prescriptionFufllmentSuccess: "prescriptionFufllmentSuccess",
  modalLoading: "modalLoading",
  deletedPrescription: "deletedPrescription",
};
const DELIVERYTYPE = {
  pickup: "pick-up",
  delivery: "delivery",
};

const ERRORTYPE = {
  deleted: "deleted",
  other: "other",
};

const FulfillmentModalV2 = ({
  isOpen,
  onClose,
  pharmacies,
  lgas,
  consultationId,
  service,
  prescriptionId,
  prescriptions,
}) => {
  const [modalContent, setModalContent] = useState(
    MODALNAVIGATOR.fulfilmentBase
  );
  const [contactDetails, setContactDetails] = useState({});
  const [selectedPharmacy, setSelectedPharmacy] = useState({});
  const [showBackIcon, setShowBackIcon] = useState(true);
  const [deliveryType, setDeliveryType] = useState(null);
  const [pickupType, setPickupType] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const isModalWithoutBackIcon = [
      // set modal contents without back buton/icon
      MODALNAVIGATOR.confirmSelectedPharmacy,
      MODALNAVIGATOR.prescriptionFufllmentSuccess,
      MODALNAVIGATOR.modalLoading,
      MODALNAVIGATOR.deletedPrescription,
      MODALNAVIGATOR.deliverOption,
    ].includes(modalContent);

    setShowBackIcon(!isModalWithoutBackIcon);
  }, [modalContent]);

  const regex = new RegExp("(reliance family clinic[s]?)|(rfc)", "i");

  const rfcPharmacies = pharmacies.filter((item) => regex.test(item.name));
  const otherPhacies = pharmacies.filter((item) => !regex.test(item.name));

  const handleSelectedPharmacy = (pharmacy) => {
    setSelectedPharmacy(pharmacy);
    setModalContent(MODALNAVIGATOR.confirmSelectedPharmacy);
  };

  const handleGoBack = () => {
    switch (modalContent) {
      case MODALNAVIGATOR.fullfillmentOptions:
        setModalContent(MODALNAVIGATOR.contactDetails);
        return;
      case MODALNAVIGATOR.contactDetails:
        setModalContent(MODALNAVIGATOR.fulfilmentBase);
        return;
      case MODALNAVIGATOR.deliverOption:
      case MODALNAVIGATOR.pickupOption:
      case MODALNAVIGATOR.pickupOptionRecommended:
        setModalContent(MODALNAVIGATOR.fullfillmentOptions);
        return;
      case MODALNAVIGATOR.confirmSelectedPharmacy:
        selectedPharmacy.isRecommended
          ? setModalContent(MODALNAVIGATOR.pickupOptionRecommended)
          : setModalContent(MODALNAVIGATOR.pickupOption);
        return;
      case MODALNAVIGATOR.prescriptionFufllmentSuccess:
        onClose();
        window.location.reload();
        return;
      case MODALNAVIGATOR.deletedPrescription:
        onClose();
        return;
      default:
        onClose();
    }
  };

  const handleRFCLocations = () => {
    const state = contactDetails?.state?.toLowerCase();
    if (!state) return "";

    const matchingAreas = rfcPharmacies
      .filter((rfc) => rfc.state.toLowerCase() === state)
      .map((rfc) => rfc.area)
      .filter(Boolean);

    switch (matchingAreas.length) {
      case 0:
        return "";
      case 1:
        return matchingAreas[0];
      case 2:
        return matchingAreas.join(" and ");
      default:
        return `${matchingAreas
          .slice(0, -1)
          .join(", ")} and ${matchingAreas.slice(-1)}`;
    }
  };

  const filteredOptions = (pharmacies, filterValue) => {
    const optionValues = new Set();

    if (filterValue) {
      pharmacies.forEach((pharmacy) => {
        optionValues.add(pharmacy.state_object[filterValue]);
      });
    } else {
      pharmacies.forEach((pharmacy) => {
        optionValues.add(pharmacy.state_object);
      });
    }
    return [...optionValues];
  };

  const handleOnProceedPickup = () => {
    setDeliveryType(DELIVERYTYPE.pickup);
    setModalContent(MODALNAVIGATOR.pickupOption);
  };

  const handleOnProceedDelivery = () => {
    setModalContent(MODALNAVIGATOR.deliverOption);
    setDeliveryType(DELIVERYTYPE.delivery);
  };

  const handleOnProceedRecommendedPickup = () => {
    setModalContent(MODALNAVIGATOR.pickupOptionRecommended);
    setDeliveryType(DELIVERYTYPE.pickup);
  };

  const renderModalContent = () => {
    switch (modalContent) {
      case MODALNAVIGATOR.deletedPrescription:
        return (
          <FulfillmentError
            error={error}
            onClick={handleGoBack}
            ERRORTYPE={ERRORTYPE}
            handleGoBack={handleGoBack}
            tryAgain={handlePrescriptionFulfillment}
            prescriptionId={prescriptionId}
          />
        );
      case MODALNAVIGATOR.modalLoading:
        return (
          <div style={{ height: "300px" }}>
            <LoadingScreen />;
          </div>
        );
      case MODALNAVIGATOR.fulfilmentBase:
        return (
          <FulfillmentBase
            prescriptions={prescriptions}
            onClose={onClose}
            modalNavigator={MODALNAVIGATOR}
            setModalContent={() =>
              setModalContent(MODALNAVIGATOR.contactDetails)
            }
          />
        );
      case MODALNAVIGATOR.fullfillmentOptions:
        return (
          <FulfilmentOptions
            modalNavigator={MODALNAVIGATOR}
            onProceedDelivery={handleOnProceedDelivery}
            onProceedRecommendedPickup={handleOnProceedRecommendedPickup}
            onProceedPickup={handleOnProceedPickup}
            handleRFCLocations={handleRFCLocations}
            setDeliveryType={setDeliveryType}
            DELIVERYTYPE={DELIVERYTYPE}
            showRfc={contactDetails.isRfcPresent}
            hasDelivery={contactDetails.hasDelivery}
            contactDetails={contactDetails}
            prescriptionId={prescriptionId}
          />
        );
      case MODALNAVIGATOR.deliverOption:
        return (
          <FulfilmentDelivery
            handleGoBack={handleGoBack}
            onProceed={handlePrescriptionFulfillment}
            contactDetails={contactDetails}
            prescriptionId={prescriptionId}
          />
        );
      case MODALNAVIGATOR.pickupOptionRecommended:
        return (
          <FulfilmentRFCPickup
            pharmacy={rfcPharmacies.filter(
              (pharm) => pharm.state_object.name === contactDetails.state
            )}
            contactDetails={contactDetails}
            handleSelectedPharmacy={handleSelectedPharmacy}
            setPickupType={setPickupType}
            prescriptionId={prescriptionId}
          />
        );
      case MODALNAVIGATOR.pickupOption:
        return (
          <FulfilmentPharmacyListV2
            pharmacies={otherPhacies.filter(
              (pharmacy) =>
                pharmacy.state_object.name.toLowerCase() ===
                contactDetails.state.toLowerCase()
            )}
            handleSelectedPharmacy={handleSelectedPharmacy}
            key={"other pharmacy lists"}
            contactDetails={contactDetails}
            setPickupType={setPickupType}
            prescriptionId={prescriptionId}
          />
        );
      case MODALNAVIGATOR.contactDetails:
        return (
          <ContactDetails
            defaultValues={contactDetails}
            setContactDetails={setContactDetails}
            prescriptions={prescriptions}
            setHeader={() => setHeader("Prescription")}
            onClose={onClose}
            setModalContent={setModalContent}
            states={filteredOptions(pharmacies)}
            statesOptions={filteredOptions(pharmacies, "name").sort((a, b) =>
              a.localeCompare(b)
            )}
            lgas={lgas}
            MODALNAVIGATOR={MODALNAVIGATOR}
            rfcPharmacies={rfcPharmacies}
            pharmacies={pharmacies}
          />
        );
      case MODALNAVIGATOR.confirmSelectedPharmacy:
        return (
          <ConfirmPharmacySelectionV2
            pharmacy={selectedPharmacy}
            handleGoback={handleGoBack}
            handleProceed={handlePrescriptionFulfillment}
            contactDetails={contactDetails}
            pickupType={pickupType}
            prescriptionId={prescriptionId}
          />
        );
      case MODALNAVIGATOR.prescriptionFufllmentSuccess:
        return (
          <FulfilmentConfirmation
            deliveryType={deliveryType}
            handleBackToConsultation={handleGoBack}
            DELIVERYTYPE={DELIVERYTYPE}
            contactDetails={contactDetails}
            pickupType={pickupType}
            prescriptionId={prescriptionId}
          />
        );
      default:
        return null;
    }
  };

  const handleFulfillmentError = (e) => {
    if (
      (e?.type || "").toLowerCase() === "model not found" ||
      e?.code === 404
    ) {
      setError({ ...e, type: ERRORTYPE.deleted });
      setModalContent(MODALNAVIGATOR.deletedPrescription);
    } else {
      setError({ ...e, type: ERRORTYPE.other });
    }
  };

  const handlePrescriptionFulfillment = async () => {
    const {
      alternativePhoneNumber,
      address,
      phoneNumber,
      state,
      landmark,
      lga,
    } = contactDetails;
    const setLoading = () => setModalContent(MODALNAVIGATOR.modalLoading);
    const setSuccess = () =>
      setModalContent(MODALNAVIGATOR.prescriptionFufllmentSuccess);

    const handleError = (error) => {
      handleFulfillmentError(error);
      setModalContent(MODALNAVIGATOR.deletedPrescription);
    };

    let payload = {
      phone: phoneNumber,
      alternate_phone_number: alternativePhoneNumber,
      address,
      landmark,
      lga_id: lga?.value,
    };

    try {
      setLoading();

      if (deliveryType === DELIVERYTYPE.pickup) {
        const {
          provider_id,
          name,
          address: pharmacyAddress,
          code,
        } = selectedPharmacy;
        payload = {
          ...payload,
          provider_id,
          metadata: {
            name,
            address: pharmacyAddress,
            pharmacy_code: code || "N/A",
          },
        };

        await service.put(
          ENDPOINTS.consultations.prescriptionsPickup(
            consultationId,
            prescriptionId
          ),
          payload
        );
      } else if (deliveryType === DELIVERYTYPE.delivery) {
        const stateId = pharmacies.find((val) => val.state === state)
          ?.state_object?.id;
        payload = {
          ...payload,
          state_id: stateId,
          phone_number: phoneNumber,
        };

        await service.post(
          ENDPOINTS.consultations.prescriptionsDelivery(
            consultationId,
            prescriptionId
          ),
          payload
        );
      }

      setSuccess();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Modal key="modal" open={isOpen} onOpenChange={onClose}>
      <Modal.Content blank>
        {prescriptions && prescriptions.delivery_option ? (
          <DeliveryStatus
            setHeader={() => "Prescriptions"}
            prescriptions={prescriptions}
            deliveryMethod={deliveryType}
            newFlow={true}
          />
        ) : (
          <>
            {showBackIcon && (
              <div
                onClick={handleGoBack}
                style={{
                  display: "flex",
                  gap: 6,
                  fontSize: 15,
                  cursor: "pointer",
                  marginBottom: 8,
                }}
              >
                <img src={Back} alt="back" width={15} height={40} /> Go back
              </div>
            )}
            {renderModalContent()}
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};

function mapStateToProps(state) {
  const user = state.session.user;
  const prescriptionId = state.consultation.prescriptionId;
  const prescriptions = state.consultation.prescriptions;
  return { user, prescriptionId, prescriptions };
}

export default withRouter(connect(mapStateToProps)(FulfillmentModalV2));
