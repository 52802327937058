import { isFeatureEnabled } from "@reliance/feature-flags";

import FulfillmentModalV1 from "./FulfillmentModal";
import FulfillmentModalV2 from "./FulfillmentModalV2";
import { features } from "../../../services/constants";

export default isFeatureEnabled(features.fulfillmentV2)
  ? FulfillmentModalV2
  : FulfillmentModalV1;
// export default FulfillmentModalV1;
