import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import moment from "moment";
import * as _ from "lodash";
import userService from "../services/userService";
import MainContent from "./boxes/MainContent";
import PageHeader from "./boxes/PageHeader";
import PageBody from "./boxes/PageBody";
import LoadingScreen from "./LoadingScreen";
import ButtonBar from "./boxes/ButtonBar";
import StatsDataCard from "./boxes/StatsDataCard";
import DashboardProfileCard from "./boxes/DashboardProfileCard";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import tourDashboard from "./tours/tourDashboard";
import { withTranslation } from "react-i18next";

class DashboardHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usageData: {
        tipsRead: 0,
        questionsAsked: 0,
        hospitalVisits: 0,
      },
      hasDependants:
        this.props.user.planInfo &&
        this.props.user.planInfo.limits.dependants > 0,
      isLoading: false,
      isTourOpen: false,
      isShowingMore: false,
    };
  }

  disableBody = (target) => disableBodyScroll(target);
  enableBody = (target) => enableBodyScroll(target);

  closeTour = () => {
    this.setState({ isTourOpen: false });
    localStorage.setItem("isTourOpen", false);
  };

  openTour = () => {
    this.setState({ isTourOpen: true });
  };

  componentDidMount() {
    if (localStorage.getItem("isTourOpen") == null) {
      localStorage.setItem("isTourOpen", true);
      this.setState({ isTourOpen: true });
    } else {
      let isTourOpenSync = localStorage.getItem("isTourOpen");
      this.setState({ isTourOpen: JSON.parse(isTourOpenSync) });
    }

    userService.getUsageData().then((data) => {
      this.setState({
        usageData: data,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.user?.planInfo?.limits?.dependants !==
      this.props.user?.planInfo?.limits?.dependants
    ) {
      this.setState({
        hasDependants: this.props.user?.planInfo?.limits?.dependants > 0,
      });
    }
  }

  addDependants = () => this.setState({ addDependants: true });
  render() {
    const { usageData, addDependants, hasDependants, isTourOpen } = this.state;
    const { user } = this.props;

    if (_.isEmpty(user)) return <LoadingScreen />;

    if (addDependants) {
      return (
        <Redirect
          push
          to={{
            pathname: `/profile`,
            state: {
              addDependants: true,
            },
          }}
        />
      );
    }

    return (
      <MainContent>
        <PageHeader title={this.props.t("sidebar.dashboard")} />

        <PageBody>
          <ButtonBar
            dataTut="step_two_tour"
            main={{
              name: this.props.t("sidebar.consult_doctor"),
              func: () => this.props.history.push("/consultations"),
            }}
            other={
              hasDependants && {
                name: this.props.t("my_profile.add_family"),
                func: this.addDependants,
              }
            }
          />
          {/* Refer and earn suspended */}
          {/* <div className="dashboard-refer-banner">
                        <button
                            className="dashboard-refer-banner__button"
                            onClick={() => this.props.history.push('/refer')}
                        >
                            <i className="fa fa-info-circle"></i> Invite friends, earn money! Tap
                            here
                        </button>
                    </div> */}

          <DashboardProfileCard
            image={user.imageUrl}
            details={getUserProfileDetails(user, this.props.t, this.props.i18n)}
          />

          <div className="dashboard-card contact-us">
            <h2>
              {this.props.t("dashboard.problem_text-1")}{" "}
              <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}>
                {process.env.REACT_APP_CONTACT_EMAIL}
              </a>{" "}
              {this.props.t("general.or")}{" "}
              <a href={`tel:${process.env.REACT_APP_CONTACT_PHONE_NUMBER}`}>
                {process.env.REACT_APP_CONTACT_PHONE_NUMBER}
              </a>{" "}
              {this.props.t("dashboard.problem_text-2")}
            </h2>
          </div>

          <div className="stats-data-wrap">
            <div className="stats-data-card" data-tut="step_three_tour">
              {/* taken out for Egypt */}
              {/* <StatsDataCard
                                name="Tips Read"
                                data={usageData.tipsRead}
                                iconClassName="fa-info-circle"
                            />
                            <StatsDataCard
                                name={this.props.t('dashboard.questions_asked')}
                                data={usageData.questionsAsked}
                                iconClassName="fa-question-circle"
                            /> */}
              <StatsDataCard
                name={this.props.t("dashboard.hospital_visits")}
                data={usageData.hospitalVisits}
                iconClassName="fa-hospital"
              />
            </div>
          </div>
        </PageBody>
        <Tour
          onRequestClose={this.closeTour}
          steps={tourDashboard(this.props.t, this.props.i18n)}
          isOpen={isTourOpen}
          maskClassName="mask"
          className="helper"
          rounded={12}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
        />
      </MainContent>
    );
  }
}

function getUserProfileDetails(user, t, i18n) {
  moment.locale(i18n.language);

  return {
    groupOne: [
      {
        name: t("dashboard.enrollee_name"),
        value: `${user.firstName} ${user.lastName}`,
      },
      {
        name: t("dashboard.enrollee_id"),
        value: user.hmoId,
      },
    ],
    groupTwo: [
      {
        name: t("dashboard.plan_info"),
        value: user.planInfo
          ? `${user.planInfo.product.name}`
          : `${user.plan} ${user.planType}`,
      },
      {
        name: t("dashboard.expires_on"),
        value: user.planInfo
          ? moment(user.planInfo.cover_end_date)
              .locale(i18n.language)
              .format("ddd, Do MMMM YYYY")
          : t("general.loading"),
      },
    ],
  };
}

const DashboardHomeWithTranslation = withTranslation(["webapp", "tour"])(
  DashboardHome
);
export default withRouter(DashboardHomeWithTranslation);
