import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getCookie,
    getDefaultLanguageText,
    getSupportedLocales,
    isLocalLanguageOptionAvailableInCurrentCountry
} from '../../../services/utilityService';
import { languages } from '../constants';
import { useFeatureFlags } from '../FeatureFlagWrapper';
import locales from '../../../config/localesConfig';

const useLanguageSettings = () => {
    const { i18n } = useTranslation('common');
    const [selectedLanguage, setSelectedLanguage] = useState(() => {
        return getDefaultLanguageText(languages, locales[i18n.language]?.toUpperCase());
    });
    const { flags } = useFeatureFlags();

    useEffect(() => {
        const storedLangCode = localStorage.getItem('languageOption');
        const primaryLocaleCode = locales[getSupportedLocales('primary')];

        if (isLocalLanguageOptionAvailableInCurrentCountry()) {
            document.getElementById('root').dir = i18n.dir();
        }

        if (
            locales[i18n.language]?.toUpperCase() !==
                languages[primaryLocaleCode?.toUpperCase()]?.value &&
            i18n.language !== getCookie('i18next')
        ) {
            i18n.changeLanguage(primaryLocaleCode).then(() => {
                setSelectedLanguage(flags.primaryLocaleCode);
                localStorage.setItem('languageOption', primaryLocaleCode?.toUpperCase());
                localStorage.removeItem('isTourOpen');
            });
        }
    }, [i18n.language]);

    return {
        selectedLanguage,
        setSelectedLanguage,
        isCurrentLanguageDirectionLTR: () => {
            const currentLang = locales[i18n.language]?.toUpperCase();
            const foundLanguage = Object.keys(languages).find(
                (key) => languages[key]?.value === currentLang
            );
            return !foundLanguage || i18n.dir() === 'ltr';
        }
    };
};

export default useLanguageSettings;
