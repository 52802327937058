import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { withRouter } from 'react-router-dom';
import * as providerActions from '../actions/provider';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';
import LoadingScreen from '../components/LoadingScreen';
import CenteredContentBlock from '../components/boxes/CenteredContentBlock';
import tourHospitals from '../components/tours/tourHospitals';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { withTranslation } from 'react-i18next';

class ProviderContainer extends React.Component {
    constructor() {
        super();
        this.state = {
            searchInput: '',
            isTourOpen: false,
            isShowingMore: false
        };
    }

    disableBody = (target) => disableBodyScroll(target);
    enableBody = (target) => enableBodyScroll(target);

    closeTour = () => {
        this.setState({ isTourOpen: false });
    };

    openTour = () => {
        this.setState({ isTourOpen: true });
    };

    toggleAlignmentBasedOnLaguageType = () => this.props.i18n.language.toLowerCase() === 'ar' ?  'text_right' : 'text_left';

    componentDidMount() {
        const { dispatch, user } = this.props;
        dispatch(providerActions.fetchProviders(user.credentials.accessToken));
        // this.openTour()

        if (localStorage.getItem('isTourOpen') == null) {
            localStorage.setItem('isTourOpen', true);
            this.setState({ isTourOpen: true });
        } else {
            let isTourOpenSync = localStorage.getItem('isTourOpen');
            //alert('isTourOpenSync :: '+isTourOpenSync)
            // console.log("isTourOpenSync",isTourOpenSync)
            this.setState({ isTourOpen: JSON.parse(isTourOpenSync) });
        }
    }

    renderLoading() {
        return (
            <MainContent>
                <LoadingScreen />
            </MainContent>
        );
    }

    handleSearch = (event) => {
        this.setState({
            searchInput: event.target.value.substr(0, 10)
        });
    };

  renderContent() {
    const { searchInput, isTourOpen } = this.state;
    const { providers } = this.props;

    let filteredProviders = providers;

    const filterList = (arrObj) => {
      const searchInputToLower = searchInput.toLowerCase();

            return arrObj.filter((provider) => {
                return (
                    provider.name.toLowerCase().includes(searchInputToLower) ||
                    provider.address.toLowerCase().includes(searchInputToLower) ||
                    provider.state.name.toLowerCase().includes(searchInputToLower)
                );
            });
        };

    if (searchInput) {
      filteredProviders = filterList(providers);
    }

        return (
            <MainContent>
                <PageHeader
                    title={this.props.t('hospitals.hospitals')}
                    showSearchBar
                    searchBarPlaceholder={this.props.t('hospitals.search_hospitals')}
                    handleSearch={this.handleSearch}
                    dataTut="step_search_hospital_tour"
                />
                <PageBody>
                    <div className="dashboard-card dashboard-table-wrap" data-tut="step_five_tour">
                        <div className="dashboard-table-overflow">
                            {filteredProviders.length === 0 ? (
                                <CenteredContentBlock>
                                    <div style={{ fontSize: '40px', color: 'grey' }}>
                                        {this.props.t('hospitals.no_result')}
                                    </div>
                                </CenteredContentBlock>
                            ) : (
                                <table className="dashboard-table">
                                    <thead>
                                        <tr>
                                            <th className={`dashboard-table-item dashboard-table-item__header ${this.toggleAlignmentBasedOnLaguageType()}`}>
                                                {this.props.t('general.name')}
                                            </th>
                                            <th className={`dashboard-table-item dashboard-table-item__header ${this.toggleAlignmentBasedOnLaguageType()}`}>
                                                {this.props.t('hospitals.address')}
                                            </th>
                                            <th className={`dashboard-table-item dashboard-table-item__header ${this.toggleAlignmentBasedOnLaguageType()}`}>
                                                {this.props.t('hospitals.governorate')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredProviders.map((provider, index) => (
                                            <tr key={`${provider.id}-${index}`}>
                                                <td className={`dashboard-table-item dashboard-table-item__body ${this.toggleAlignmentBasedOnLaguageType()}`}>
                                                    {provider.name}
                                                </td>
                                                <td className={`dashboard-table-item dashboard-table-item__body ${this.toggleAlignmentBasedOnLaguageType()}`}>
                                                    {provider.address}
                                                </td>
                                                <td className={`dashboard-table-item dashboard-table-item__body ${this.toggleAlignmentBasedOnLaguageType()}`}>
                                                    {provider.state.name}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </PageBody>
                <Tour
                    onRequestClose={this.closeTour}
                    steps={tourHospitals(this.props.t, this.props.i18n)}
                    isOpen={isTourOpen}
                    maskClassName="mask"
                    className="helper"
                    rounded={12}
                    onAfterOpen={this.disableBody}
                    onBeforeClose={this.enableBody}
                />
            </MainContent>
        );
    }
    render() {
        const { providers, isFetching } = this.props;
        if (!isFetching && !_.isEmpty(providers)) return this.renderContent();
        return this.renderLoading();
    }
}
function mapStateToProps(state) {
    let { providers, isFetching, error } = state.provider;
    return { providers, isFetching, error };
}

const ProviderContainerWithTranslation = withTranslation(['webapp', 'tour'])(ProviderContainer);
export default withRouter(connect(mapStateToProps)(ProviderContainerWithTranslation));
