import React, { useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
// import { stateProfile } from "./modals/FulfillmentModal";
import { connect } from "react-redux";

const selector = formValueSelector("fulfillmentDetailsForm");

const ReduxField = ({
  label,
  component,
  type,
  name,
  placeholder,
  required,
  options,
  defaultOption,
  meta,
  title,
  ...props
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "8px",
        alignSelf: "stretch",
        marginBottom: 24,
      }}
    >
      <label htmlFor={{ name }}>{label}</label>
      <Field
        component={component ? component : "input"}
        type={type ? type : "text"}
        name={name}
        placeholder={placeholder}
        required={required ? required : true}
        title={title}
        style={{
          width: "372px",
          height: "40px",
          padding: 10,
          flexShrink: 0,
          borderRadius: "4px",
          background: "#EEE",
          color: "#000",
          fontSize: "14px",
          fontFamily: "Avenir LT Std",
          fontStyle: "normal",
          fontWeight: 350,
          lineHeight: "20px",
          letterSpacing: "0.15px",
          borderColor: "#107BC0",
        }}
        {...props}
      >
        {component && component === "select" && (
          <React.Fragment>
            <option key="option0" value={""}>
              {defaultOption}
            </option>
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </React.Fragment>
        )}
      </Field>
    </div>
  );
};

let InitializeFromStateForm = ({
  setHeader,
  states,
  profile,
  setModalContent,
  pristine,
  invalid,
  submitting,
  hasDelivery,
  ...props
}) => {
  useEffect(() => {
    setHeader("Fulfillment Details");
  }, [setHeader]);

  const { deliveryMethod } = props.formValues;

  const onSubmit = (val) => {
    props.populateFulfilmentData(val);
    const { state, phoneNumber, address, deliveryMethod } = val;
    if (!state || !phoneNumber || !address || !deliveryMethod) {
      return;
    }
    if (deliveryMethod === "pickUp") {
      setModalContent("fulfillmentPharmacyList");
    } else {
      setModalContent("confirmPharmacySelection");
      setHeader("Select Pharmacy");
    }
    setHeader("Confirm Pharmacy selection");
  };

  return (
    <div
      style={{
        color: "#636363",
        fontSize: "14px",
        fontFamily: "Avenir LT Std",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "20px",
        letterSpacing: "0.15px",
      }}
    >
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <ReduxField
          label={"State/Region"}
          name="state"
          component={"select"}
          options={states}
          defaultOption={"What state/region are you in?"}
          onChange={(val) => hasDelivery(val.target.value)}
        />
        <div
          className=""
          style={{
            marginBottom: 24,
          }}
        >
          {props.formValues.state && (
            <div className="">
              <p style={{ marginBottom: 16 }}>
                How do you want you prescription fulfilled
              </p>
              <div
                className=""
                style={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <div className="">
                  <Field
                    type="radio"
                    component="input"
                    value="pickUp"
                    name="deliveryMethod"
                    style={{ width: "30px" }}
                    onChange={props.handledeliveryMethodChange}
                  />{" "}
                  Pickup
                </div>
                {props.homeDelivery && (
                  <div className="">
                    <Field
                      type="radio"
                      component="input"
                      value="delivery"
                      name="deliveryMethod"
                      style={{ width: "30px" }}
                      onChange={props.handledeliveryMethodChange}
                    />{" "}
                    Delivery
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="" style={{ marginBottom: 24 }}>
          Please confirm that the below address and phone number is accurate and
          valid
        </div>
        <ReduxField label={"Street Address"} name="address" />
        <ReduxField
          label={"Phone Number"}
          name="phoneNumber"
          placeholder={"+2348012345678"}
          pattern="^\+234(0[789]\d{9}|[789]\d{9})$"
          title={"You have entered an invalid phone number. Please try again."}
        />
        {props.formValues.state && (
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              alignSelf: "stretch",
            }}
          >
            <p
              className=""
              style={{
                display: "flex",
                width: "311px",
                flexDirection: "column",
                color: " var(--text-04, #4F4F4F)",
                textAlign: "center",
                fontSize: "14px",
                fontFamily: "Avenir LT Std",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "-0.84px",
                marginTop: 32,
              }}
            >
              {deliveryMethod === "delivery"
                ? "Requests sent after 12pm may not be fulfilled on the same day; thus if immediate attention is required, please visit a hospital"
                : "Please select pharmacy closest to your location, we will confirm the availability of the drugs and notify you via SMS/notification"}{" "}
            </p>
            <button
              type="submit"
              disabled={invalid || submitting || pristine}
              style={{
                display: "flex",
                padding: "12px 16px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                alignSelf: "stretch",
                borderRadius: "4px",
                background: "var(--primary-color-baby-blue, #107BC0)",
                boxShadow:
                  "0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15)",

                color: "var(--ui-01, #FFF)",
                textAlign: "center",
                fontSize: "12px",
                fontFamily: "Avenir LT Std",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              {deliveryMethod === "pickUp"
                ? "SELECT PICKUP PHARMACY"
                : "CONFIRM DELIVERY ADDRESS"}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

const reduxInitializeFromStateForm = reduxForm({
  form: "fulfillmentDetailsForm",
  enableReinitialize: true,
})(InitializeFromStateForm);

const mapStateToProps = (state) => {
  const profile = state.user.meta.profile || {};
  const formValues = selector(
    state,
    "phoneNumber",
    "address",
    "state",
    "deliveryMethod"
  );
  return {
    initialValues: {
      address: profile.address,
      phoneNumber: "+234" + String(profile.phone_number).slice(-10),
      deliveryMethod: "pickUp",
    },
    formValues,
  };
};

export default connect(mapStateToProps)(reduxInitializeFromStateForm);
