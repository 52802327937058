import React from "react";
import Info from "../img/Info.svg";

export default () => {
  return (
    <div
      style={{
        backgroundColor: "#FFFAF2",
        padding: 5,
        marginBottom: 10,
        marginTop: 10,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        gap: 5,
      }}
    >
      <div>
        <img src={Info} alt="" width={40} />
      </div>
      <div>
        <p>
          Medication requested after 12 noon may not be delivered on the same
          day. You must be available to receive the prescription at the provided
          address within 24 hours.
        </p>
      </div>
    </div>
  );
};
