import Immutable from 'seamless-immutable';
import {CONSULTATION_ACTION_TYPES} from '../actions/actionTypes';

const initialState = Immutable({
    newMessages: {},
    endConsultationConfirmations: {},
    openedConsultationStatus: {}
});

export default function consultationReducer(state=initialState, action) {
    switch(action.type) {
        case CONSULTATION_ACTION_TYPES.DISPLAY_NEW_MESSAGE:
            if (state.newMessages[action.newMessage.id]) {
                return state
            }
            return Immutable(state).merge({
                newMessages: {...state.newMessages, [action.newMessage.id]: {...action.newMessage, display: true} }
            })
        case CONSULTATION_ACTION_TYPES.CLEAR_NEW_MESSAGE:
            const removedNewMessages = {};
            Object.keys(state.newMessages).forEach(msgKey => {
                if (state.newMessages[msgKey].consultation_id === action.consultation_id) {
                    removedNewMessages[msgKey] = {...state.newMessages[msgKey], display: false}
                }
            })
            return Immutable(state).merge({
                newMessages: {...state.newMessages, ...removedNewMessages}
            })
        case CONSULTATION_ACTION_TYPES.ACTIVATE_END_CONSULTATION:
            if (state.endConsultationConfirmations[action.data.consultation_id]) {
                return state
            }
            return Immutable(state).merge({
                endConsultationConfirmations: {
                    ...state.endConsultationConfirmations,
                    [action.data.consultation_id]: {
                        ...action.data
                    } 
                }
            })
        case CONSULTATION_ACTION_TYPES.CLEAR_END_CONSULTATION:
            const prunedConfirmations = {};
            Object.keys(state.endConsultationConfirmations).forEach(consultation_id => {
                if (parseInt(consultation_id) !== action.consultation_id) {
                    prunedConfirmations[consultation_id] = {...state.endConsultationConfirmations[consultation_id]}
                }
            })
            return Immutable(state).merge({
                endConsultationConfirmations: {...prunedConfirmations}
            })
        case CONSULTATION_ACTION_TYPES.UPDATE_CONSULTATION_STATUS:
            return Immutable(state).merge({
                openedConsultationStatus: action.data
            })
        case CONSULTATION_ACTION_TYPES.UPDATE_PRESCRIPTION_ID:
            return Immutable(state).merge({
                prescriptionId: action.id
            })
        case CONSULTATION_ACTION_TYPES.UPDATE_PRESCRIPTION_DETAIL:
            return Immutable(state).merge({
                prescriptions:action.data
            })
        default:
            return state;
    }
};
