import { Button, Heading } from "@reliance/design-system";
import React from "react";

const FulfillmentBase = ({ setModalContent, onClose, prescriptions }) => {
  const capitalizeWordsExceptInBrackets = (input) => {
    let inBrackets = false;
    let result = "";

    for (let i = 0; i < input.length; i++) {
      if (input[i] === "(") {
        inBrackets = true;
      } else if (input[i] === ")") {
        inBrackets = false;
      }

      if (inBrackets) {
        result += input[i];
      } else if (/[a-zA-Z]/.test(input[i])) {
        if (i === 0 || !/[a-zA-Z]/.test(input[i - 1])) {
          result += input[i].toUpperCase();
        } else {
          result += input[i];
        }
      } else {
        result += input[i];
      }
    }

    return result;
  };

  const date = new Date(prescriptions?.created_at);

  // Extracting the date components
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are zero-based, so we add 1
  const day = date.getDate();

  // Creating a formatted date string
  const formattedDate = `${day < 10 ? "0" + day : day}/${
    month < 10 ? "0" + month : month
  }/${year}`;

  return (
    <div
      style={{
        color: "#636363",
        fontSize: "14px",
        fontFamily: "Avenir LT Std",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "20px",
        letterSpacing: "0.15px",
      }}
    >
      <div>
        <h2
          style={{
            color: "#333333",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "700",
          }}
        >
          Prescribed Items - {prescriptions?.prescription_drugs?.length}
        </h2>

        <p
          style={{
            fontWeight: "350",
            fontSize: "10px",
            lineHeight: "20px",
            color: "#999",
          }}
        >
          On {formattedDate}
        </p>

        {!prescriptions?.prescription_drugs ? (
          <p>No prescribed drugs available right now</p>
        ) : null}

        <div
          style={{
            marginTop: "14px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          {prescriptions?.prescription_drugs?.map((drug) => (
            <div
              key={drug?.id} // Add a unique key to each mapped element
              style={{
                minWidth: "350px",
                height: "fit-content",
                border: "1px solid #F4F4F4",
                borderRadius: "4px",
                padding: "16px",
              }}
            >
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#107BC0",
                  lineHeight: "20px",
                  marginBottom: "4px",
                }}
              >
                {drug?.drug?.name}
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#4F4F4F",
                  lineHeight: "20px",
                  marginBottom: "4px",
                }}
              >
                {capitalizeWordsExceptInBrackets(
                  `${drug?.form}, ${drug?.dosage}, ${drug?.frequency} for ${drug?.duration}`
                )}
              </p>

              <div
                style={{
                  margin: "4px 0 4px 0",
                  width: "fit-content",
                  height: "fit-content",
                  padding: "4px 6px 4px 6px",
                  borderWidth: "1px",
                  borderRadius: "3px",
                  backgroundColor: "#F8F8F8",
                }}
              >
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "11px",
                    textAlign: "center",
                    color: "#42526E",
                    textTransform: "lowercase",
                  }}
                >
                  {`${drug?.quantity} ${drug?.form} dispensed`}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 10,
                  marginBottom: "8px",
                }}
              >
                {drug?.diagnosis?.map((symptom) => (
                  <div
                    key={symptom?.id}
                    style={{
                      backgroundColor: "#E4E5E7",
                      padding: "2px 8px 2px 8px",
                      borderRadius: 5,
                    }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#202223",
                      }}
                    >
                      {symptom?.name}
                    </p>
                  </div>
                ))}
              </div>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#C4C4C4",
                  marginBottom: "4px",
                }}
              >
                Comments:
              </p>

              <p
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#4F4F4F",
                }}
              >
                {drug?.comment ? drug?.comment : "No comments"}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "36px",
        }}
      >
        <button
          className={`w-full h-[54px] text-white bg-[#094063] py-3 rounded-[128px] text-[15px] leading-[22px] border-none`}
          type="submit"
          onClick={() => {
            setModalContent("fulfillmentDetails");
          }}
        >
          Proceed with Prescription
        </button>

        <button
          style={{
            marginTop: "14px",
            display: "flex",
            padding: "12px 16px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            alignSelf: "stretch",
            borderWidth: "0",
            borderRadius: "4px",
            background: "transparent",
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: "12px",
            fontFamily: "Avenir LT Std",
            fontStyle: "15px",
            fontWeight: "600",
            lineHeight: "22px",
            color: "#323539",
            cursor: "pointer",
          }}
          onClick={() => {
            onClose();
          }}
        >
          Go back to Consultation
        </button>
      </div>
    </div>
  );
};

export default FulfillmentBase;
