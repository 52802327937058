import moment from "moment";
import React from "react";
import RecommendedIcon from "../img/check-circle.svg";

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const getDisplayStyle = (pharmacy) => {
  const hasPickup =
    pharmacy.has_pickup && pharmacy.has_pickup.toLowerCase() === "yes";
  const deliveryOption =
    pharmacy.delivery_option &&
    pharmacy.delivery_option.toLowerCase() === "pickup";
  return hasPickup || deliveryOption ? "flex" : "none";
};
const pharmacyDaySchedule = (arr) => {
  let startTime, endTime;
  if (Array.isArray(arr)) {
    const todaySchedule = arr.filter(
      (val) => val?.day.toLowerCase() === moment().format("dddd").toLowerCase()
    );
    const openHourStatus = todaySchedule[0]?.status;
    startTime = "09:00";
    endTime = "19:30";
    if (openHourStatus === "3") {
      startTime = todaySchedule[0]?.start_time;
      endTime = todaySchedule[0]?.end_time;
    }
  }
  return {
    startTime,
    endTime,
  };
};

export default ({ pharmacy, isSelected, onClick }) => {
  const { startTime, endTime } = pharmacyDaySchedule(pharmacy?.opening_hours);

  return (
    <div
      className=""
      style={{
        display: getDisplayStyle(pharmacy),
        padding: "16px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        border: isSelected
          ? "2px solid var(--primary-color-baby-blue, #107BC0)"
          : "",
        alignSelf: "stretch",
        marginBottom: 16,
        borderRadius: "4px",
        background: "var(--ui-04, #F8F8F8)",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "8px",
          alignSelf: "stretch",
        }}
      >
        <div
          style={{
            color: "var(--text-01, #212121)",
            fontSize: "16px",
            fontFamily: "Avenir LT Std",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: "0.15px",
          }}
        >
          {toTitleCase(pharmacy?.name)}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "stretch",
            color: "var(--text-01, #212121)",
            fontSize: "15px",
            fontFamily: "Avenir LT Std",
          }}
        >
          {pharmacy?.address}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: "12px",
            fontWeight: 600,
            alignItems: "center",
            justifyContent: "space-between",
            alignSelf: "stretch",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: "13px",
              fontWeight: 600,
              lineHeight: "18px",
              gap: "5px",
            }}
          >
            <span style={{ color: "var(--alert-success, #09A55A)" }}>
              Open:
            </span>
            <span>
              {startTime} - {endTime}
            </span>
          </div>

          {pharmacy?.is_recommended ? (
            <div
              style={{
                backgroundColor: "#DFE1E6",
                padding: "4px 6px 4px 6px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <p>Recommended</p>

              <img
                src={RecommendedIcon}
                width={"100%"}
                alt="recommended icon"
                style={{
                  marginBottom: "2px",
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
